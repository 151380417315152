import GetTenant from "@/lib/GetTenant";
import { getFQCN } from "@/lib/helpers/fqcn/methods";
import {
  HiArrowTopRightOnSquare,
  HiOutlineHome,
  HiOutlinePresentationChartBar,
} from "react-icons/hi2";

const fqcnBUI = {
  Bundle: "sidebarMenuBundle",
  Unit: "menu",
  Interface: "Listing",
};

export const SidebarMenus = [
  {
    title: "Home",
    icon: (
      <HiOutlineHome
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcnBUI, "menu", "home-icon")}
      />
    ),
    link: "/",
  },
  {
    title: "Task Manager",
    icon: (
      <HiOutlinePresentationChartBar
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcnBUI, "menu", "invoice-icon")}
      />
    ),
    link: "/task-manager",
  },
  {
    title: "Legacy",
    icon: (
      <HiArrowTopRightOnSquare
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcnBUI, "menu", "legacy-icon")}
      />
    ),
    link: "https://" + GetTenant() + ".phpreaction.com/project/task_manager",
    externalLink: true,
  },
];
